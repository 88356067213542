import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import "./navbar.css";
import { Dropdown } from "react-bootstrap";
import menu from "../../assets/images/ICONOS/menu.png";

export const Navbar = ({ transparent }) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg  fixed-top ${
          transparent ? "transparent" : "navbar-light bg-light"
        }`}
      >
        <div className=" " id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active"></li>
            <li className="nav-item active">
              <Link to="/">Inicio</Link>
            </li>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">Posada</Dropdown.Toggle>

              <Dropdown.Menu>
                <AnchorLink to="/posada" title="Historia" stripHash />
                <AnchorLink
                  to="/posada#exteriores"
                  title="Exteriores"
                  stripHash
                />
                <AnchorLink to="/posada#huerto" title="Huerto" stripHash />
                <AnchorLink to="/posada#animales" title="Animales" stripHash />
              </Dropdown.Menu>
            </Dropdown>{" "}
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">Servicios</Dropdown.Toggle>

              <Dropdown.Menu>
                <AnchorLink
                  to="/servicios/alojamiento"
                  title="Alojamiento"
                  stripHash
                />
                <AnchorLink
                  to="/servicios/restaurante"
                  title="Restaurante"
                  stripHash
                />

                <AnchorLink
                  to="/servicios/panaderia"
                  title="Panadería"
                  stripHash
                />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Experiencias
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <AnchorLink
                  to="/experiencias#environment"
                  title="Entorno"
                  stripHash
                />
                <AnchorLink
                  to="/experiencias#gastronomy"
                  title="Gastronomía"
                  stripHash
                />
                <AnchorLink
                  to="/experiencias#actividades"
                  title="Actividades"
                  stripHash
                />
              </Dropdown.Menu>
            </Dropdown>
            <li className="nav-item">
              <Link to="/agradecimientos">Agradecimientos</Link>
            </li>
            <li className="nav-item">
              <Link to="/contacto">Contacto</Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="button-mobile">
        <img src={menu} onClick={() => setShow(!show)} />
      </div>
      <div className={`sidenav ${show ? "" : "hide"}`}>
        <img src={menu} onClick={() => setShow(!show)} />
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active"></li>
          <li className="nav-item active">
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <AnchorLink to="/posada" title="Posada" stripHash />
          </li>
          <li>
            <AnchorLink
              to="/servicios/alojamiento"
              title="Alojamiento"
              stripHash
            />
          </li>
          <li>
            <AnchorLink to="/servicios/panaderia" title="Panaderia" stripHash />
          </li>
          <li>
            <AnchorLink
              to="/servicios/restaurante"
              title="Restaurante"
              stripHash
            />
          </li>
          <li>
            <AnchorLink to="/experiencias#entorno" title="Experiencias" stripHash />
          </li>
          <li>
            <AnchorLink to="/agradecimientos" title="Agradecimientos" stripHash />
          </li>
          <li className="nav-item">
            <Link to="/contacto">Contacto</Link>
          </li>
        </ul>
      </div>
    </>
  );
};
