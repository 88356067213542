import * as React from "react";
import "./avisoLegal.css";
const AvisoLegal = () => {
  return (
    <>
      <section
        class="container"
        style={{ paddingTop: "100px", paddingBottom: "150px" }}
      >
        <div class="row">
          <div class="col-12 fix desc">
            <h1>Aviso Legal de Posada Los Jeronimos</h1>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>DATOS IDENTIFICATIVOS</strong>
              </span>
            </p>
            <p>
              En cumplimiento con el deber de información recogido en artículo
              10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad
              de la Información y del Comercio Electrónico, a continuación, se
              reflejan los siguientes datos: la empresa titular de dominio web
              es Celia Villa Chávez 71041998-N(en adelante POSADA LOS JERONIMOS), con
              domicilio a estos efectos en direccion,&nbsp; &nbsp; del sitio
              web.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>USUARIOS</strong>
              </span>
            </p>
            <p>
              El acceso y/o uso de este portal de POSADA LOS JERONIMOS atribuye
              la condición de USUARIO, que acepta, desde dicho acceso y/o uso,
              las Condiciones Generales de Uso aquí reflejadas. Las citadas
              Condiciones serán de aplicación independientemente de las
              Condiciones Generales de Contratación que en su caso resulten de
              obligado cumplimiento.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>USO DEL PORTAL</strong>
              </span>
            </p>
            <p>
              www.visualquiality.es proporciona el acceso a multitud de
              informaciones, servicios, programas o datos (en adelante, “los
              contenidos”) en Internet pertenecientes a POSADA LOS JERONIMOS o a
              sus licenciantes, a los que el USUARIO pueda tener acceso. El
              USUARIO asume la responsabilidad del uso del portal. Dicha
              responsabilidad se extiende al registro que fuese necesario para
              acceder a determinados servicios o contenidos.
            </p>
            <p>
              En dicho registro el USUARIO será responsable de aportar
              información veraz y lícita. Como consecuencia de este registro, al
              USUARIO se le puede proporcionar una contraseña de la que será
              responsable, comprometiéndose a hacer un uso diligente y
              confidencial de la misma.
            </p>
            <p>
              El USUARIO se compromete a hacer un uso adecuado de los contenidos
              y servicios (como por ejemplo servicios de chat, foros de
              discusión o grupos de noticias) que POSADA LOS JERONIMOS creadora
              del sitio web ofrece a través de su portal y con carácter
              enunciativo, pero no limitativo, a no emplearlos para:
            </p>
            <ul>
              <li>
                Incurrir en actividades ilícitas, ilegales o contrarias a la
                buena fe y al orden público.
              </li>
              <li>
                Difundir contenidos o propaganda de carácter racista, xenófobo,
                pornográfico-ilegal, de apología del terrorismo o atentatorio
                contra los derechos humanos.
              </li>
              <li>
                Provocar daños en los sistemas físicos y lógicos de Nombre de la
                empresa creadora del sitio web, de sus proveedores o de terceras
                personas, introducir o difundir en la red virus informáticos o
                cualesquiera otros sistemas físicos o lógicos que sean
                susceptibles de provocar los daños anteriormente mencionados.
              </li>
              <li>
                Intentar acceder y, en su caso, utilizar las cuentas de correo
                electrónico de otros usuarios y modificar o manipular sus
                mensajes.
              </li>
            </ul>
            <p>
              POSADA LOS JERONIMOS creadora del sitio web se reserva el derecho
              de retirar todos aquellos comentarios y aportaciones que vulneren
              el respeto a la dignidad de la persona, que sean discriminatorios,
              xenófobos, racistas, pornográficos, que atenten contra la juventud
              o la infancia, el orden o la seguridad pública o que, a su juicio,
              no resultaran adecuados para su publicación. En cualquier caso,
              POSADA LOS JERONIMOS no será responsable de las opiniones vertidas
              por los usuarios a través de los foros, chats, u otras
              herramientas de participación.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>PROTECCIÓN DE DATOS</strong>
              </span>
            </p>
            <h4>INFORMACIÓN ADICIONAL DETALLADA SOBRE PROTECCIÓN DE DATOS</h4>
            <p>
              Este sitio Web, se compromete en el cumplimiento de la regulación
              vigente (RGPD – Reglamento UE 2016/679) en materia de protección
              de datos.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>
                ¿Quién es el responsable del tratamiento de sus datos?
              </strong>
            </p>
            <ul>
              <li>Empresa: Posada Los Jeronmios</li>
              <li>NIF: 71.041.998-N</li>
              <li>
                DIRECCION C/ Santa Lucía 13, CP 49173, la Tuda, Zamora.
                &nbsp;&nbsp;&nbsp;&nbsp;
              </li>
              <li>Teléfono: 980551240</li>
              <li>Dirección electrónica: info@losjeronimos.com</li>
              <li>Web: losjeronimos.com</li>
            </ul>
            <p>
              <strong>
                ¿Con qué finalidad y por cuánto tiempo tratamos sus datos
                personales?
              </strong>
            </p>
            <ul>
              <li>
                Como finalidad principal en la empresa tratamos la información
                que nos facilitan las personas interesadas con el fin de
                gestionar la relación contractual que nos vincula mientras dure
                dicho contrato o durante los años necesarios para cumplir con
                las obligaciones legales.
                <ul>
                  <li>
                    Adicionalmente, y solo en el caso de que usted haya aceptado
                    las condiciones sobre el tratamiento de datos y dado su
                    consentimiento, trataremos los datos identificativos para
                    comunicaciones y/o información técnica y comercial.
                  </li>
                  <li>
                    Los datos se mantendrán mientras no se solicite su supresión
                    por el interesado o hasta que se ejercite el derecho de
                    oposición al tratamiento.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              <strong>
                ¿Cuál es la legitimación para el tratamiento de sus datos?
              </strong>
            </p>
            <ul>
              <li>
                La base legal de los tratamientos es la aceptación junto con el
                consentimiento que se le solicita y el cumplimiento de las
                obligaciones legales que correspondan en cada caso.
              </li>
            </ul>
            <p>
              <strong>¿A qué destinatarios se comunicarán sus datos?</strong>
            </p>
            <ul>
              <li>
                Los destinatarios de los datos que se tratan serán, la empresa
                responsable del tratamiento de los datos, con la finalidad de
                llevar a cabo la gestión comercial y administrativa y las
                Administraciones Públicas competentes para el cumplimiento de
                obligaciones legales.
              </li>
              <li>
                Los datos no se cederán a terceros, salvo en los casos que
                exista una obligación legal.
              </li>
            </ul>
            <p>
              <strong>
                ¿Cuáles son sus derechos cuando nos facilita sus datos?
              </strong>
            </p>
            <ul>
              <li>
                Cualquier persona tiene derecho a obtener confirmación sobre si
                en la empresa estamos tratando datos personales que le
                conciernen o no, a acceder a sus datos personales, así como a
                solicitar la rectificación de los datos inexactos o, en su caso,
                solicitar su supresión cuando, entre otros motivos, los datos ya
                no sean necesarios para los fines que fueron recogidos.
              </li>
              <li>
                Derecho a la portabilidad de los datos:en caso de que quiera que
                sus datos sean tratados por otra empresa, POSADA LOS JERONIMOS
                le facilitará la portabilidad de sus datos al nuevo responsable.
              </li>
              <li>
                Cuando el tratamiento tenga su base legal en el consentimiento,
                tendrá derecho a revocar dicho consentimiento en cualquier
                momento.
              </li>
              <li>
                Para el ejercicio de estos derechos podrá dirigirse por escrito,
                a la dirección postal o a la dirección electrónica de la empresa
                responsable del tratamiento de los datos, incluyendo en ambos
                casos fotocopia de su DNI u otro documento de identificación
                similar.
              </li>
              <li>
                Las personas interesadas además tienen derecho a presentar una
                reclamación ante la Agencia Española de Protección de Datos si
                considera que el tratamiento de datos no es acorde con la
                normativa de protección de datos actualmente vigente.
              </li>
            </ul>
            <p>
              Modelos, formularios y más información sobre los derechos
              referidos en la página oficial de la Agencia Española de
              Protección de Datos:
              <a href="https://www.aepd.es/reglamento/derechos/index.html">
                https://www.aepd.es/reglamento/derechos/index.html
              </a>
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>Recepción de Currículums Vitae</strong>
            </p>
            <p>
              El aspirante que envíe su currículum y datos personales a través
              de la página web www.visualquality.es, a través del correo postal,
              a través del correo electrónico o a través de cualquier otro
              medio,&nbsp; &nbsp;<strong>AUTORIZA</strong> a POSADA LOS
              JERONIMOS al tratamiento de los datos enviados según la
              legislación vigente en materia de protección de datos y según lo
              expuesto en los párrafos anteriores. También{" "}
              <strong>AUTORIZA</strong> &nbsp;a&nbsp;analizar&nbsp;los
              documentos&nbsp;que remita, así como a analizar todo&nbsp;el
              contenido que sea directamente accesible a través de los
              buscadores (Bing, Google, Baidu, DuckDuckGo, etc…), a analizar los
              perfiles públicos que mantenga en redes sociales profesionales
              (Linkedin, Facebook, Xing, Viadeo, etc…),&nbsp;a analizar los
              datos obtenidos en&nbsp;las pruebas de acceso y la información que
              revele en la entrevista de trabajo, con el objetivo
              de&nbsp;valorar su candidatura y poder, en su caso, ofrecerle un
              puesto de trabajo. POSADA LOS JERONIMOS podrá usar&nbsp;los datos
              debidamente anonimizados para hacer estadísticas sobre el tipo de
              persona que se presenta como candidato, con la posibilidad de
              elaborar informes sobre ello.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS por sí o como cesionaria, es titular de todos
              los derechos de propiedad intelectual e industrial de su página
              web, así como de los elementos contenidos en la misma (a título
              enunciativo, pero no limitativo: imágenes, sonidos, audios,
              vídeos, software o textos; marcas o logotipos, combinaciones de
              colores, estructura y diseño, selección de materiales usados,
              programas de ordenador necesarios para su funcionamiento, acceso y
              uso, etc.), son titularidad de POSADA LOS JERONIMOS o bien de sus
              licenciantes.
            </p>
            <p>
              Todos los derechos reservados. En virtud de lo dispuesto en los
              artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad
              Intelectual, quedan expresamente prohibidas la reproducción, la
              distribución y la comunicación pública, incluida su modalidad de
              puesta a disposición, de la totalidad o parte de los contenidos de
              esta página web, con fines comerciales, en cualquier soporte y por
              cualquier medio técnico, sin la autorización de VISUALQUALITY
              STUDIO. El USUARIO se compromete a respetar los derechos de
              Propiedad Intelectual e Industrial titularidad de VISUALQUALITY
              STUDIO. Podrá visualizar los elementos del portal e incluso
              imprimirlos, copiarlos y almacenarlos en el disco duro de su
              ordenador o en cualquier otro soporte físico siempre y cuando sea,
              única y exclusivamente, para su uso personal y privado. El USUARIO
              deberá abstenerse de suprimir, alterar, eludir o manipular
              cualquier dispositivo de protección o sistema de seguridad que
              estuviera instalado en el las páginas de POSADA LOS JERONIMOS.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS no se hace responsable, en ningún caso, de
              los daños y perjuicios de cualquier naturaleza que pudieran
              ocasionar, a título enunciativo, pero no limitativo: errores u
              omisiones en los contenidos, falta de disponibilidad del portal o
              la transmisión de virus o programas maliciosos o lesivos en los
              contenidos, a pesar de haber adoptado todas las medidas
              tecnológicas necesarias para evitarlo.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>MODIFICACIONES</strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS se reserva el derecho de efectuar sin previo
              aviso las modificaciones que considere oportunas en su portal,
              pudiendo cambiar, suprimir o añadir tanto los contenidos y
              servicios que se presten a través de la misma como la forma en la
              que éstos aparezcan presentados o localizados en su portal.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>ENLACES</strong>
              </span>
            </p>
            <p>
              En el caso de que en www.visualquiality.es se dispusiesen enlaces
              o hipervínculos hacía otros sitios de Internet, POSADA LOS
              JERONIMOS no ejercerá ningún tipo de control sobre dichos sitios y
              contenidos.
            </p>
            <p>
              En ningún caso POSADA LOS JERONIMOS asumirá responsabilidad alguna
              por los contenidos de algún enlace perteneciente a un sitio web
              ajeno, ni garantizará la disponibilidad técnica, calidad,
              fiabilidad, exactitud, amplitud, veracidad, validez y
              constitucionalidad de cualquier material o información contenida
              en ninguno de dichos hipervínculos u otros sitios de Internet.
            </p>
            <p>
              Igualmente la inclusión de estas conexiones externas no implicará
              ningún tipo de asociación, fusión o participación con las
              entidades conectadas.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>DERECHO DE EXCLUSIÓN</strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS se reserva el derecho a denegar o retirar el
              acceso a portal y/o los servicios ofrecidos sin necesidad de
              preaviso, a instancia propia o de un tercero, a aquellos usuarios
              que incumplan las presentes Condiciones Generales de Uso.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>GENERALIDADES</strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS perseguirá el incumplimiento de las presentes
              condiciones, así como cualquier utilización indebida de su portal
              ejerciendo todas las acciones civiles y penales que le puedan
              corresponder en derecho.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>
                  MODIFICACIÓN DE LAS PRESENTES CONDICIONES Y DURACIÓN
                </strong>
              </span>
            </p>
            <p>
              POSADA LOS JERONIMOS podrá modificar en cualquier momento las
              condiciones aquí determinadas, siendo debidamente publicadas como
              aquí aparecen.
            </p>
            <p>
              La vigencia de las citadas condiciones irá en función de su
              exposición y estarán vigentes hasta que sean modificadas por otras
              debidamente publicadas.
            </p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>
                  FOMENTO DEL DESARROLLO DE ACTIVIDADES ECONÓMICAS POR CUENTA
                  PROPIA EN LA COMUNIDAD DE CASTILLA Y LEÓN PARA EL AÑO 2020
                </strong>
              </span>
            </p>
            <p>
              Esta actividad económica ha sido subvencionada al amparo de la
              orden EEI/531/2020, de 17 de junio, de la Consejería de Empleo e
              Industria por las que se establecen las bases reguladoras de las
              subvenciones, cofinanciadas por el Fondo Social Europeo,
              destinadas a fomentar el desarrollo de actividades económicas por
              cuenta propia en la Comunidad de Castilla y León, con cargo al
              programa operativo de Castilla y León 2014-2020.
            </p>
            <p>Objetivo: conseguir un empleo y formación de calidad.</p>
            <p>&nbsp;</p>
            <p>
              <span style={{ color: "#72635B" }}>
                <strong>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</strong>
              </span>
            </p>
            <p>
              La relación entre POSADA LOS JERONIMOS y el USUARIO se regirá por
              la normativa española vigente y cualquier controversia se someterá
              a los Juzgados y Tribunales de la ciudad de ZAMORA.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </section>
    </>
  );
};
export default AvisoLegal;
