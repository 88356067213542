import ReactModal from "react-modal";
import * as React from "react";

const CookiesComponent = () => {
  const [modal, setModal] = React.useState(true);
  const customStyles = {
    overlay: { zIndex: 1000 },
  };

  const acceptCookies = () => {
    typeof window !== "undefined" && localStorage.setItem("agree", true);
  };
  return (
    <ReactModal
      isOpen={modal}
      onRequestClose={() => {}}
      contentLabel="Example Modal In Gatsby"
      style={customStyles}
    >
      <div>
        <p>
          Nosotros y nuestros socios almacenamos o accedemos a información en un
          dispositivo, tales como cookies, y procesamos datos personales, tales
          como identificadores únicos e información estándar enviada por un
          dispositivo, para anuncios y contenido personalizados, medición de
          anuncios y del contenido e información sobre el público, así como para
          desarrollar y mejorar productos.
        </p>
        <p>
          Con su permiso, nosotros y nuestros socios podemos utilizar datos de
          localización geográfica precisa e identificación mediante las
          características de dispositivos. Puede hacer clic para otorgarnos su
          consentimiento a nosotros y a nuestros socios para que llevemos a cabo
          el procesamiento previamente descrito. De forma alternativa, puede
          acceder a información más detallada y cambiar sus preferencias antes
          de otorgar o negar su consentimiento.
        </p>
        <p>
          {" "}
          Tenga en cuenta que algún procesamiento de sus datos personales puede
          no requerir de su consentimiento, pero usted tiene el derecho de
          rechazar tal procesamiento. Sus preferencias se aplicarán solo a este
          sitio web. Puede cambiar sus preferencias en cualquier momento
          entrando de nuevo en este sitio web o visitando nuestra política de
          privacidad.
        </p>
      </div>
      <div
        className="wrapper-boton-aceptar"
        style={{ justifyContent: "space-around" }}
      >
        <div
          className="boton-aceptar-modal"
          onClick={() => {
            setModal(false);
            acceptCookies();
          }}
        >
          Aceptar
        </div>
      </div>
    </ReactModal>
  );
};

export default CookiesComponent;
