import * as React from "react";
import "./footer.css";
import ReactModal from "react-modal";
import AvisoLegal from "../AvisoLegal/avisoLegal";

const Footer = ({ slim }) => {
  const [modal, setModal] = React.useState(false);
  const customStyles = {
    overlay: { zIndex: 1000 },
  };
  return (
    <>
      {!slim ? (
        <footer className="footer-big">
          <div className="footer-wrapper">
            <div>
              <span className="footer-title">Contacto</span>
              <ul className="contacto-list">
                <li>Bº La Herrán 43, 49023 Zamora</li>
                <li>9242 528 933 | 662 171 724</li>
              </ul>
            </div>
            <div>
              <span className="footer-title">Posada la corralada</span>
              <ul className="footer-list">
                <li>Posada la corrala</li>
                <li>Casona del Siglo XIX</li>
                <li>Entorno</li>
              </ul>
            </div>
            <div>
              <span className="footer-title">Nuestras habitaciones</span>
              <ul className="footer-list">
                <li>Habitación Ámbar</li>
                <li>Habitación Perla</li>
                <li>Habitación Griseta</li>
              </ul>
            </div>
            <div>
              <span className="footer-title">¿Por qué visitarnos?</span>
              <ul className="footer-list">
                <li>Posada rural para estancia vacacional en cantabria</li>
                <li>Casa rural para familias con niños</li>
                <li>San Valentín romántico en Cantabria</li>
              </ul>
            </div>
          </div>
        </footer>
      ) : (
        <>
          <footer className="footer-slim">
            <div className="footer-wrapper">
              <div>Posada Los Jerónimos</div>
              <div style={{ cursor: "pointer" }} onClick={() => setModal(true)}>
                Aviso Legal
              </div>
              <div className="rs-wrapper">
                <div className="logo-footer">
                  <a
                    href="https://www.instagram.com/losjeronimos/"
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </div>

                <div className="logo-footer">
                  <a
                    href="https://www.facebook.com/Posada-Rural-Los-Jer%C3%B3nimos-La-Tuda-Zamora-141401149302237/"
                    target="_blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
            <ReactModal
              isOpen={modal}
              onRequestClose={() => {
                console.log("e");
              }}
              contentLabel="Example Modal In Gatsby"
              style={customStyles}
            >
              <div
                className="boton-cerrar-modal"
                onClick={() => setModal(false)}
              >
                {" "}
                X
              </div>
              <AvisoLegal></AvisoLegal>
              <div className="wrapper-boton-aceptar">
                <div
                  className="boton-aceptar-modal"
                  onClick={() => setModal(false)}
                >
                  Aceptar
                </div>
              </div>
            </ReactModal>
          </footer>
          
        </>
      )}
    </>
  );
};

export default Footer;
